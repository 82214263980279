<template>
  <!--服务商订单汇总统计列表-->
  <div style="padding: 20px;">
    <div class="title">
      {{ $route.meta.title }}
    </div>

    <!--搜索条件-->
    <el-card shadow="never" style="margin-top: 20px;">
      <div class="card">
        <div class="card_t">
          <div class="card_t_icon"></div>
          <div class="card_t_text">
            筛选条件
          </div>
        </div>
        <div class="card_b">
          <!-- 搜索表单 -->
          <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent>
            <el-row :gutter="15">
              <el-col :lg="6" :md="12">
                <el-form-item label="服务商名称:">
                  <el-input
                      clearable
                      v-model="where.keyword"
                      placeholder="服务商名称"/>
                </el-form-item>
              </el-col>
              <!--<el-col :lg="6" :md="12">-->
              <!--  <el-form-item label="服务商名称:">-->
              <!--    <el-input-->
              <!--        clearable-->
              <!--        v-model="where.facilitator_name"-->
              <!--        placeholder="请输入客户名称"/>-->
              <!--  </el-form-item>-->
              <!--</el-col>-->
              <el-col :lg="6" :md="12">
                <el-form-item label="选择年月:">
                  <el-date-picker
                      v-model="where.time"
                      type="month"
                      placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :lg="6" :md="12">
                <div class="ele-form-actions">
                  <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList">查询
                  </el-button>
                  <el-button @click="empty">清空</el-button>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </el-card>

    <!--数据表格-->
    <el-card shadow="never">
      <div class="card">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              数据列表
            </div>
          </div>
        </div>

        <div class="card_b">
          <!-- 数据表格 -->
          <!--<my-table-->
          <!--    ref="myTable"-->
          <!--    :columns="columns"-->
          <!--    :tableData="List"-->
          <!--    :cellStyle="cellStyle"-->
          <!--    :headerCellStyle="headerCellStyle"-->
          <!--    :rowClassName="rowClassName"-->
          <!--    :total="total"-->
          <!--    :customsFromWhere="where"-->
          <!--    :loading="loading"-->
          <!--    @select="select"-->
          <!--    @ChangeSize="ChangeSize"-->
          <!--    @currentChange="currentChange"-->
          <!--&gt;-->
          <!--</my-table>-->

          <el-table
              :data="List"
              align="center"
              style="width: 100%;text-align: center;">
            <el-table-column label="基础类" align="center">
              <el-table-column
                  prop="name"
                  align="center"
                  label="服务商名称">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="服务商级别">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="服务区域">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="总金额">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="总单量">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="客单价">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="份额单量">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="聚合单量">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="司机数量">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="车辆数量">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="调度人员数量">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="接单方式">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="状态合格率">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="状态合格单量">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="照片合格单量">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="照片合格率">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="结算类型">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="合作状态">
              </el-table-column>
            </el-table-column>
            <el-table-column label="时效类" align="center">
              <el-table-column
                  prop="name"
                  align="center"
                  label="平均接单时效">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="平均到达时效">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="平均完成时效">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="40分钟完结率">
              </el-table-column>
            </el-table-column>
            <el-table-column label="质量类" align="center">
              <el-table-column
                  prop="name"
                  align="center"
                  label="催单单量">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="催单率">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="投诉单量">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="投诉率">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="NPS好评单量">
              </el-table-column>
              <el-table-column
                  prop="name"
                  align="center"
                  label="客户好评率">
              </el-table-column>
            </el-table-column>

            <el-table-column label="项目类" align="center">
              <el-table-column
                  prop="name"
                  align="center"
                  label="拖车">
                <el-table-column
                    prop="name"
                    align="center"
                    label="拖车单量">
                </el-table-column>
                <el-table-column
                    prop="name"
                    align="center"
                    label="首派成功率">
                </el-table-column>
                <el-table-column
                    prop="name"
                    align="center"
                    label="改派成功率">
                </el-table-column>
                <el-table-column
                    prop="name"
                    align="center"
                    label="拒单率">
                </el-table-column>
                <el-table-column
                    prop="name"
                    align="center"
                    label="总单量">
                </el-table-column>
                <el-table-column
                    prop="name"
                    align="center"
                    label="派单成功量">
                </el-table-column>
                <el-table-column
                    prop="name"
                    align="center"
                    label="改派成功量">
                </el-table-column>
                <el-table-column
                    prop="name"
                    align="center"
                    label="拒单量">
                </el-table-column>
                <el-table-column
                    prop="name"
                    align="center"
                    label="议价金额">
                </el-table-column>
                <el-table-column
                    prop="name"
                    align="center"
                    label="平均议价率">
                </el-table-column>
                <el-table-column
                    prop="name"
                    align="center"
                    label="自动结算单量">
                </el-table-column>
                <el-table-column
                    prop="name"
                    align="center"
                    label="自动结算占比">
                </el-table-column>
              </el-table-column>
            </el-table-column>
          </el-table>

        </div>
      </div>
    </el-card>

    <!--列表设置弹窗-->
    <div class="dialog">
      <el-dialog
          title="列表设置"
          :visible.sync="ListSetupShow"
          width="60%"
          center
          :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
      </el-dialog>
    </div>

  </div>
</template>

<script>
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {
  get_order_summarizing,
  getfacilitatorApi,
  set_statusApi
} from '@/api/custom'

export default {
  // 组件生效
  components: {
    PackageSortElement
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        // {
        //   label: "编号",
        //   prop: "id",
        //   isShow: true,
        //   fixed: 'left'
        // },
        {
          label: "服务商名称",
          prop: "provider_name",
          isShow: true,
          width: 300,
        },
        {
          label: "服务商级别",
          prop: "",
          isShow: true
        },
        {
          label: "服务区域",
          prop: "",
          isShow: true
        },
        {
          label: "份额派单比例",
          prop: "",
          isShow: true
        },
        {
          label: "司机数量",
          prop: "",
          isShow: true
        },
        {
          label: "车辆数量",
          prop: "",
          isShow: true
        },
        {
          label: "调度人员数量",
          prop: "",
          isShow: true
        },
        {
          label: "接单方式",
          prop: "",
          isShow: true
        },
        {
          label: "状态合格率",
          prop: "",
          isShow: true
        },
        {
          label: "状态合格单量",
          prop: "",
          isShow: true
        },
        {
          label: "照片合格单量",
          prop: "",
          isShow: true
        },
        {
          label: "照片合格率",
          prop: "",
          isShow: true
        },
        {
          label: "合作类型",
          prop: "",
          isShow: true
        },
        {
          label: "拖车单量",
          prop: "",
          isShow: true
        },
        {
          label: "平均接单时效",
          prop: "",
          isShow: true
        },
        {
          label: "平均到达时效",
          prop: "",
          isShow: true
        },
        {
          label: "平均完成时效",
          prop: "",
          isShow: true
        },
        {
          label: "40分钟完结率",
          prop: "",
          isShow: true
        },
        {
          label: "催单单量",
          prop: "",
          isShow: true
        },
        {
          label: "催单率",
          prop: "",
          isShow: true
        },
        {
          label: "投诉单量",
          prop: "",
          isShow: true
        },
        {
          label: "投诉率",
          prop: "",
          isShow: true
        },
        {
          label: "NPS客户好评单量",
          prop: "",
          isShow: true
        },
        {
          label: "客户好评率",
          prop: "",
          isShow: true
        },
        {
          label: "总派单量",
          prop: "total_order_quantity",
          isShow: true
        },
        {
          label: "系统正常派单量",
          prop: "system_total_order_quantity",
          isShow: true
        },
        {
          label: "首派派单量",
          prop: "first_order_quantity",
          isShow: true
        },
        {
          label: "改派派单量",
          prop: "revise_order_quantity",
          isShow: true
        },
        {
          label: "首派成功单量",
          prop: "first_order_success_quantity",
          isShow: true
        },
        {
          label: "改派成功单量",
          prop: "revise_order_success_quantity",
          isShow: true
        },
        {
          label: "服务完成量",
          prop: "service_completion_total",
          isShow: true
        },
        {
          label: "客户评价单量",
          prop: "client_evaluation_total",
          isShow: true
        },
        {
          label: "客户评价得分",
          prop: "customer_service_score",
          isShow: true
        },
        {
          label: "平均派单时长",
          prop: "average_order_duration",
          isShow: true
        },
        {
          label: "平均达到时长",
          prop: "average_arrival_time",
          isShow: true
        },
        {
          label: "平均服务时长",
          prop: "average_service_time",
          isShow: true
        },
        {
          label: "派单份额",
          prop: "quota_dispatch",
          isShow: true
        },
        {
          label: "系统单占比",
          prop: "system_single_ratio",
          isShow: true
        },
        {
          label: "首派单占比",
          prop: "first_order_ratio",
          isShow: true
        },
        {
          label: "首派成功率(总)",
          prop: "first_delivery_success_rate",
          isShow: true
        },
        {
          label: "首派成功率(路修)",
          prop: "first_delivery_success_road",
          isShow: true
        },
        {
          label: "首派成功率(困境)",
          prop: "first_delivery_success_plight",
          isShow: true
        },
        {
          label: "首派成功率(拖车)",
          prop: "first_delivery_success_trailer",
          isShow: true
        },
        {
          label: "改派成功率(总)",
          prop: "reassignment_success_rate",
          isShow: true
        },
        {
          label: "改派成功率(路修)",
          prop: "reassignment_success_road",
          isShow: true
        },
        {
          label: "改派成功率(困境)",
          prop: "reassignment_success_plight",
          isShow: true
        },
        {
          label: "改派成功率(拖车)",
          prop: "reassignment_success_trailer",
          isShow: true
        },
        {
          label: "派单状态完整单量",
          prop: "delivery_status_full_count",
          isShow: true
        },
        {
          label: "派单状态完整单占比",
          prop: "delivery_status_full_tatio",
          isShow: true
        },
        {
          label: "到达状态有效单量",
          prop: "arrival_state_effective_quantity",
          isShow: true
        },
        {
          label: "到达状态有效单量占比",
          prop: "arrival_state_effective_ratio",
          isShow: true
        },
        // {
        //   label: "合作状态",
        //   prop: "authentication_status",
        //   slot: "authentication_status",
        //   isShow: true
        // },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    //调用获取客户信息列表方法
    this.getList();
  },

  methods:{
    // 获取列表
    getList(){
      get_order_summarizing(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    // 跳转详情
    Todetails(){
      this.$router.push({
        path:'/custom/details.vue'
      })
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
